import {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import { black, purple} from '../../config/colors';
import {TypeMedia, MoodLabel, MediaPlaylist} from '../../services/SoundSuitServiceTypes';
import {listGenres, idToLabel, IdToLabel} from '../../config/hardData';
import {useApp} from '../../store';
import {capitalizeFirstLetter} from '../../utils/functions';
// import components
import Text from '../atoms/Text';
import Tag from '../atoms/Tag';

type Props = {
  type: TypeMedia;
  media: MediaPlaylist;
  isSmall: boolean;
  isVerySmall: boolean;
};

const PlaylistCardCalendar = (props: Props) => {

  const {media, type, isSmall, isVerySmall} = props;

  const [t] = useTranslation();

  const labelsType = useRef({
    playlist: t('Settings.playlistLabel'),
  }).current;

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;

  const [image, setImage] = useState<string>();
  const [tags, setTags] = useState<string[]>([]);
  const [tagsMood, setTagsMood] = useState<(MoodLabel | string)[]>([]);

  useEffect(() => {

    const { metrics } = media;

    if (metrics && Array.isArray(metrics.genres)) {
      const tgs = (metrics.genres as string[]).reduce<string[]>((acc, g, i) => {
        const l = listGenres.find((lg) => lg.id === g);
        if (l) {
          if (i === 1) {
            acc.push('...');
          } else if (i > 1) {
            return acc;
          } else {
            acc.push(l?.label);
          }
        }
        return acc;
      }, []);

      setTags(tgs);
    }
    if (media?.coverImage) {
      setImage(media.coverImage);
    }
    if (metrics && metrics.moods) {

      const tgsMood = (metrics.moods as string[]).reduce<(MoodLabel | string)[]>((acc, m, i) => {
        if (i === 1) {
          acc.push('...');
        } else if (i > 1) {
          return acc;
        } else {
          acc.push(m);
        }
        return acc;
      }, []);

      setTagsMood(tgsMood);
    }
  }, [media]);

  return (
    <ContainerMedia>
        <WrapperImage>{image && <Image src={image}/>}</WrapperImage>
        <MainColumn>
          <WrapperTitle>
            <Text
              color={black}
              size="normal"
              bold
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {media?.name}
            </Text>
          </WrapperTitle>
          {!isVerySmall && (
            <>
              <Text color={purple} size="xsmall">
                {`${capitalizeFirstLetter(labelsType[type])} • ${media.tracks.length} ${t('menu.labelSongs')}`}
              </Text>
              <WrapperTags>
                {tagsMood.map((g: string, i: number) => {
      
                  if (g === '...') {
                    return (
                      <Tag
                        key={`${i}-${g}`}
                        type="mood"
                        size="small"
                      >
                        {g}
                      </Tag>
                    );
                  }
      
                  return (
                    <Tag
                      key={`${i}-${g}-mood`}
                      type="mood"
                      size="small"
                    >
                        {currentIdToLabel[g]}
                    </Tag>
                  );
                })}
              </WrapperTags>
              {!isSmall && (
                <WrapperTags>
                  {tags.map((g: string, i: number) => {

                    if (g === '...') {
                      return (
                        <Tag
                          key={`${i}-${g}`}
                          type="genre"
                          size="small"
                        >
                          {g}
                        </Tag>
                      );
                    }

                    return (
                      <Tag
                        key={`${i}-${g}-mood`}
                        type="genre"
                        size="small"
                      >
                          {currentIdToLabel[g]}
                      </Tag>
                    );
                  })}
                </WrapperTags>
              )} 
            </>
          )}
        </MainColumn>
    </ContainerMedia>
  );
};

const ContainerMedia = styled.div`
  z-index: 0;
`;
const MainColumn = styled.div`
  width: 100%;
`;
const WrapperImage = styled.div`
  width: 55px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
`;
const WrapperTitle = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const WrapperTags = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  margin-bottom: 5px;
`;
const Image = styled.img`
  width: 55px;
  height: 55px;
`;

export default PlaylistCardCalendar;
