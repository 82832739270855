import {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  RightOutlined
} from '@ant-design/icons';

import {
  grey,
} from '../../config/colors';
import { TypeMedia } from '../../services/SoundSuitServiceTypes';
import { listGenres} from '../../config/hardData';
import {
  loadUserProfile,
} from '../../services/SoundSuitService';
import CalendarImg from '../../assets/images/calendarImg.jpg';
import { capitalizeFirstLetter } from '../../utils/functions';
// import components
import Text from '../atoms/Text';
import MultipleImagesThumbnail from '../atoms/MultipleImagesThumbnail';

type Props = {
  type: TypeMedia;
  media: any;
};

const LibraryRow = (props: Props) => {

  const {
    media,
    type,
  } = props;

  const [t] = useTranslation();
  const { data } = useQuery('/userinfo', loadUserProfile);

  const [images, setImages] = useState<string[]>([]);
  const [typeTrad, setTypeTrad] = useState<string>("");

  useEffect(() => {

    switch (type) {
      case 'station':
        if (media && Array.isArray(media.genres)) {
          const imgs = media.genres.map((genre: string) => listGenres.find(lg => lg.label === genre)?.img); 
          setImages(imgs);
        }
        break;
      case 'schedule':
        setImages([CalendarImg]);
        break;
        case 'playlist':
          if (media && media?.coverImage) {
            const uri = /\.(png|jpg|jpeg)$/.test(media.coverImage) ? media.coverImage : 'https://assets.soundsuit.fm/playlist-600.png';
            setImages([uri]);
          }
          break;
        default:
          break;
    }
  }, [media]);

  useEffect(() => {
    switch (type) {
      case 'station':
        setTypeTrad(t('Settings.stationLabel'))
        break;
      case 'playlist':
        setTypeTrad(t('Settings.playlistLabel'))
        break;
      case 'schedule':
        setTypeTrad(t('Schedule.ScheduleNavTitle').replace('s', ''))
        break;
      default:
        break;
    }

  }, [type]);

  return (
    <Container>
      <WrapperImage>
        {images.length > 0 && <MultipleImagesThumbnail imgs={images} size="small" />}
      </WrapperImage>
      <Column>
        <WrapperArrow>
          <RightOutlined style={{ fontSize: 24 }} />
        </WrapperArrow>
        <WrapperTitle>
          <WrapperTitleLength>
            <Text bold>{media.name || ''}</Text>
          </WrapperTitleLength>
        </WrapperTitle>
        <WrapperDescription>
          <Text
            color={grey}
            size="xsmall"
          >
            {`${capitalizeFirstLetter(typeTrad)} • By ${data.email}`}
          </Text>
        </WrapperDescription>
      </Column>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 80px;
  border-radius: 16px;
  margin-bottom: 5px;
  padding: 10px 0;
  z-index: 0;
`;
const WrapperArrow = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  z-index: 50;
`;
const Column = styled.div`
  position: relative;
  flex: 2;
  height: 100%;
  padding-left: 10px;
`;
const WrapperDescription = styled.div`
  width: 100%;
`;
const WrapperImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 10px;
  margin-bottom: 5px;
  width: 100%;
`;
const WrapperTitleLength = styled.div`
  width: 70%;
`;

export default LibraryRow;