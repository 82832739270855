import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { notification } from 'antd';
import {useQuery} from 'react-query';
import {
  PlayCircleFilled,
} from '@ant-design/icons';

import {white, veryVeryLightGrey, purple} from '../../config/colors';
import {MoodLabel, PlaylistItem} from '../../services/SoundSuitServiceTypes';
import {idToLabel, IdToLabel} from '../../config/hardData';
import {loadUserProfile} from '../../services/SoundSuitService';
import {usePlayer} from '../../store';
// import components
import Text from '../atoms/Text';
import Header from '../molecules/Header';
import Tag from '../atoms/Tag';
import MultipleImagesThumbnail from '../atoms/MultipleImagesThumbnail';

export interface ParamsPlaylist {
  media: string;
}

interface Props {
}

const PlaylistOptions: React.FC<Props> = () => {

  async function handleSelectionPlay() {
    try {
      await setMediaToPlay({id: media.id, type: 'playlist'});
      toggleRefreshTracks();
      navigate('/');
    } catch (error) {
      console.log("Error choosing media: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  }

  const setMediaToPlay = usePlayer.getState().effects.setMediaToPlay;
  const toggleRefreshTracks = usePlayer.getState().reducers.toggleRefreshTracks;
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [params, setSearchParams] = useSearchParams();
  const dataParams = Object.fromEntries([...params]);

  const {
    media: mediaPost
    // @ts-ignore
  } = dataParams as ParamsPlaylist;

  const {data: dataUser} = useQuery('/userinfo', loadUserProfile);

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;
  const [media] = useState<PlaylistItem>(JSON.parse(decodeURIComponent(mediaPost)));
  const [image, setImage] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [tagsMood, setTagsMood] = useState<(MoodLabel | undefined)[]>([]);

  useEffect(() => {
    if (media) {
      const {
        coverImage,
        metrics: {genres, moods},
      } = media;

      if (coverImage) {
        const uri = /\.(png|jpg|jpeg)$/.test(coverImage) ? coverImage : 'https://assets.soundsuit.fm/playlist-600.png';
        setImage([uri]);
      }
      setTags(genres);
      setTagsMood(moods);
    }
  }, [media]);

  return (
    <Container>
      <Header
        title={t('Settings.playlistLabel')}
        customGoBack={() => navigate('/library')}
      />
      <Body contentContainerStyle={{alignItems: 'center', paddingBottom: 60, paddingTop: 20}}>
        <WrapperImage>
          <MultipleImagesThumbnail size="big" imgs={image} />
        </WrapperImage>
        <WrapperSubHeader>
          <Text bold>{`By ${dataUser.email}`}</Text>
          <WrapperButtonPlay onClick={handleSelectionPlay}>
            <PlayCircleFilled style={{paddingLeft: 1, fontSize: 32}} />
          </WrapperButtonPlay>
        </WrapperSubHeader>
        <WrapperInfo>
          <OptionRow>
            <LabelName>
              <Text color={purple} size='xsmall' >{t('Settings.playlistNameLabel')}</Text>
            </LabelName>
            <RowCenter>
              <Text>{media.name}</Text>
            </RowCenter>
          </OptionRow>
          <OptionRow>
            <LabelName>
              <Text color={purple} size='xsmall' >{t('Settings.descriptionLabel')}</Text>
            </LabelName>
            <Text>{media.description}</Text>
          </OptionRow>
          <OptionRow>
            <Text>{t('Schedule.labelMood')}</Text>
            <RowCenter>
              {tagsMood.map((g: string, i: number) => {
                if (i > 2) return null;
                if (i === 2) {
                  return (
                    <Tag
                      key={`${i}-tagMood-${media.id}`}
                      type="mood"
                      size="xsmall"
                    >
                      ...
                    </Tag>
                  );
                }
                return (
                  <Tag key={`${i}-tagMood-${media.id}`} type="mood" size="xsmall">
                    {currentIdToLabel[g]}
                  </Tag>
                );
              })}
            </RowCenter>
          </OptionRow>
          <OptionRow>
            <Text>{t('Schedule.labelGenre')}</Text>
            <RowCenter>
              {tags.map((g: string, i: number) => {
                if (i > 2) return null;
                if (i === 2) {
                  return (
                    <Tag
                      key={`${i}-tag-${media.id}`}
                      type="genre"
                      size="xsmall"
                    >
                      ...
                    </Tag>
                  );
                }
                return (
                  <Tag key={`${i}-tag-${media.id}`} type="genre" size="xsmall">
                    {currentIdToLabel[g]}
                  </Tag>
                );
              })}
            </RowCenter>
          </OptionRow>
        </WrapperInfo>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${veryVeryLightGrey};
`;
const Body = styled.div`
  width: 100%;
  padding: 0 15px 0 15px;
`;
const OptionRow = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  background-color: ${white};
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 5px 15px 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LabelName = styled.div`
  position: absolute;
  top: -12px;
  left: 18px;
`;
const WrapperImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;
const WrapperSubHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  margin-bottom: 30px;
`;
const WrapperButtonPlay = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const WrapperInfo = styled.div`
  width: 100%;
`;

export default PlaylistOptions;
