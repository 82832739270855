import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { notification, Modal, Button as AntButton } from 'antd';
import {
  DeleteOutlined,
  LeftOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';
import {Link} from "react-router-dom";

import iconAdd from '../../assets/icons/add.png';

import { Days} from '../../services/SoundSuitServiceTypes';
import {
  deleteAllScheduleItems,
  deleteSelectedScheduleItems,
  updateCalendar,
  deleteCalendar
} from '../../services/SoundSuitService';
import { usePlayer } from '../../store';
import { lightgray, grey, redText } from '../../config/colors';
import { useSchedule } from '../../hooks';
// import components
import Title from '../atoms/Title';
import Text from '../atoms/Text';

interface Props {
  day: Days;
  idCalendar: string;
  name: string;
};

const HeaderSchedule: React.FC<Props> = props => {

  async function handleDeleteCalendar() {
    try {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(t('Schedule.deleteCalendarWarning'))) {
        await deleteCalendarById(idCalendar);
        navigate("/library");
      }
    } catch (error) {
      console.log("ERROR : ", error);
    }
  }

  async function editCalendarName() {
    if (newName) {
      try {
        await editNameCalendar({id: idCalendar, data: {name: newName}});
      } catch (error) {
        console.log("ERROR : ", error);
      }
    }
  }

  async function handleDeleteAll() {
    await deleteAllItems();
  }
  async function handleDeleteSelected() {
    const slotsday = sortTimeslotsByDay(timeslots, day);
    const tabIds = slotsday.map(s => s.id as string);
    await deleteSelectedItems(tabIds);
  }

  const {
    day,
    idCalendar,
    name
  } = props;

  const { sortTimeslotsByDay } = useSchedule();

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const timeslots = usePlayer(statePlayer => statePlayer.state.timeslots);

  const [ deleteModalOpened, setDeleteModalOpened ] = useState<boolean>(false);
  const [editName, setEditName] = useState<boolean>(false);
  const [nameCalendar, setNameCalendar] = useState<string>("");
  const [newName, setNewName] = useState<string>("");


  const { mutateAsync: deleteCalendarById } = useMutation(deleteCalendar, {
    onError: (error) => {
      console.log("ERROR: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });
  const { mutateAsync: editNameCalendar } = useMutation(updateCalendar, {
    onSuccess: () => {
      setNameCalendar(newName);
      setNewName('');
      setEditName(false);
      queryClient.invalidateQueries(['calendar-info', idCalendar]);
    },
    onError: (error) => {
      console.log("ERROR: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });
  const { mutateAsync: deleteAllItems, isLoading: isLoadingDeleteAll } = useMutation(deleteAllScheduleItems, {
    onSuccess: () => {
      queryClient.invalidateQueries(['calendar-timeslots', idCalendar]);
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
      setDeleteModalOpened(false);
    },
    onError: (error) => {
      console.log("ERROR: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });
  const { mutateAsync: deleteSelectedItems, isLoading: isLoadingSelectedDelete } = useMutation(deleteSelectedScheduleItems, {
    onSuccess: () => {
      queryClient.invalidateQueries(['calendar-timeslots', idCalendar]);
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
      setDeleteModalOpened(false);
    },
    onError: (error) => {
      console.log("ERROR: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });

  useEffect(() => {
    if (name) {
      setNameCalendar(name);
    }
  }, [name])

  return (
    <>
    <Header>
      <ButtonBack onClick={() => navigate("/library")} >
        <LeftOutlined style={{ fontSize: '24px' }} color="black" />
      </ButtonBack>
      {!editName && (
          <RowButton onClick={() => setEditName(true)}>
            <Title numberOfLines={1} bold>{nameCalendar ? nameCalendar : t('Schedule.scheduleTitle')}</Title>
            <EditOutlined style={{ color: grey, fontSize: 22, marginLeft: 10}} />
          </RowButton>
        )}
        {editName && (
          <Row>
            <Input
              placeholder='New name...'
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            <CheckOutlined onClick={editCalendarName} style={{ color: "green", fontSize: 22, marginLeft: 5}} />
            <CloseOutlined onClick={() => setEditName(false)} style={{ color: "black", fontSize: 22, marginLeft: 5}} />
          </Row>
        )}
      <BarOptions>
        <Link
          to={`item?idCalendar=${idCalendar}&day=${day}`}
        >
          <Icon src={iconAdd}/>
        </Link>
        <Button style={{ marginLeft: 20}} onClick={() => setDeleteModalOpened(true)} disabled={timeslots.length === 0}>
          <DeleteOutlined style={{ fontSize: '24px', color:timeslots.length > 0 ? "black" : lightgray }} />
        </Button>
        <ButtonText onClick={handleDeleteCalendar}>
          <Text size='xsmall' bold color={redText}>{t('Schedule.deleteCalendarLabel')}</Text>
        </ButtonText>
      </BarOptions>
    </Header>
    <Modal
      title={t('Schedule.deleteItemsTitle')}
      onCancel={() => setDeleteModalOpened(false)}
      visible={deleteModalOpened}
      closable
      footer={null}
    >
      <WrapperButtons>
      <AntButton danger onClick={handleDeleteAll} >
        {t('Schedule.deleteAllItems')}
      </AntButton>
      <AntButton danger onClick={handleDeleteSelected} >
        {`${t('Schedule.DeleteItemsOf')} ${t(`Schedule.${day}`)}`}
      </AntButton>
      </WrapperButtons>
    </Modal>
    </>
  );
}

const Header = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const BarOptions = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0;
`;
const Icon = styled.img`
  width: 25px;
  height: 25px;
`;
const Button = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const WrapperButtons = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const ButtonBack = styled.div`
  cursor: pointer;
`;
const ButtonText = styled.div`
  cursor: pointer;
  margin-left: 20px;
`;
const RowButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 75%;
  cursor: pointer;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;
const Input = styled.input`
  border: solid 1px lightgray;
  border-radius: 8px;
  width: 200px;
  height: 42px;
  padding: 3px 0 3px 5px;
  &:focus {
    outline: none;
  }
`;

export default HeaderSchedule;