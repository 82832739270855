import styled from 'styled-components';

import {TypeMedia} from '../../services/SoundSuitServiceTypes';
// import components
import StationCardCalendar from './StationCardCalendar';
import PlaylistCardCalendar from './PlaylistCardCalendar';

type Props = {
  type: TypeMedia;
  media: any;
  isSmall: boolean;
  isVerySmall: boolean;
};

const MediaCardCalendar = (props: Props) => {

  const {media, type, isSmall, isVerySmall} = props;

  return (
    <Container>
      {type === 'station' && <StationCardCalendar media={media} type={type} isSmall={isSmall} isVerySmall={isVerySmall} />}
      {type === 'playlist' && <PlaylistCardCalendar media={media} type={type} isSmall={isSmall} isVerySmall={isVerySmall} />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 10px;
  z-index: 0;
`;

export default MediaCardCalendar;
