import styled from 'styled-components';

import {
 tagGenre,
 tagMood,
 tagAudience,
 tagLanguage,
 tagMedias,
 tagPurple,
 tagRed,
 black,
 white
} from '../../config/colors';
// import components
import Text from './Text';

type Color = {
  bg: string;
  txt: string;
};

const colors: {[key: string]: Color} = {
  'genre': { bg: tagGenre, txt: white},
  'mood': { bg: tagMood, txt: black},
  'target': { bg: tagAudience, txt: black},
  'language': { bg: tagLanguage, txt: white},
  'medias': { bg: tagMedias, txt: tagPurple},
  'selected': { bg: tagRed, txt: white},
};

type Props = {
  children: string;
  type: 'genre' | 'mood' | 'target' | 'language' | 'medias' | 'selected';
  size?: 'normal' | 'small' | 'xsmall';
  maxWidth?: number | string;
  bold?: boolean;
};

const Tag: React.FC<Props> = (props) => {

  const {
    children,
    type,
    size = 'normal',
    maxWidth,
    bold = false
  } = props;

  return (
    <Container
      color={colors[type].bg}
      size={size}
      maxWidth={maxWidth}
    >
      <Text
        color={colors[type].txt}
        size={size}
        bold={bold}
      >
        {children}
      </Text>
    </Container>
  );
}

const Container = styled.div<{color: string, size: 'normal' | 'small' | 'xsmall'; maxWidth?: number | string}>`
  max-width:${({maxWidth}) => {
    if (maxWidth) {
      return typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth;
    } else {
      return 'auto'
    }
  }};
  flex-direction: center;
  align-items: center;
  padding: ${({size}) => (size === "normal" || size === "xsmall") ? '6px' :' 0px 6px 1px 6px'};
  background-color: ${({color}) => color};
  border-radius: 20px;
  margin-right: 5px;
`;

export default Tag;