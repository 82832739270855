import React, {useRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import { Outlet } from "react-router-dom";

import { useApp } from '../../store';
// import components
import LibrarySearch from './LibrarySearch';


interface Props {};

const LibraryScreen: React.FC<Props> = props => {

  const globalRef = useRef(null);
  const [width, setWidth] = useState(0);

  const menuOn = useApp(state => state.state.menuOn);

  useEffect(() => {
    if (!globalRef.current || globalRef.current === null) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if(globalRef.current && globalRef.current.offsetWidth !== width) {
        setWidth(globalRef.current.offsetWidth); 
      }
    });
    resizeObserver.observe(globalRef.current);

    return function cleanup() {
      resizeObserver.disconnect();
    }
  }, [globalRef.current]);


  return (
    <>
      <GlobalWrapper
        ref={globalRef}
      >
        <Container style={{ width: `${width - (menuOn ? 90 : 0)}px` }}>
          <LibrarySearch />
        </Container>
      </GlobalWrapper>
      <Outlet />
    </>
  );
}

const GlobalWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-end;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 100%;
  overflow-y: scroll;
`;

export default LibraryScreen;