import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { veryVeryLightGrey } from "../../config/colors";
import { Gender, Styles } from "../../services/SoundSuitServiceTypes";
// import components
import Header from "../molecules/Header";
import TargetOptions from "./TargetOptions";

interface Props {}

const OptionsTargetScreen: React.FC<Props> = (props) => {
  function handleSelectStyles(id: Styles) {
    const copyStyle: Styles[] = styles.slice();
    if (styles.includes(id)) {
      const index: number = copyStyle.findIndex((s) => s === id);
      copyStyle.splice(index, 1);
    } else {
      copyStyle.push(id);
    }
    setStyles(copyStyle);
  }

  const navigate = useNavigate();

  const location = useLocation();
  const loaded = useRef(false);

  const [params, setSearchParams] = useSearchParams();
  const data = Object.fromEntries([...params]);

  const [gender, setGender] = useState<Gender>("man");
  const [age, setAge] = useState<[number, number]>([20, 30]);
  const [styles, setStyles] = useState<Styles[]>(['alternative']);

  const { t } = useTranslation();

  useEffect(() => {
    if (!loaded.current && typeof data?.target !== "undefined") {
      loaded.current = true;
      const { gender, styles, ageRange } = JSON.parse(
        decodeURIComponent(data.target)
      );
      setGender(gender);
      setStyles(styles);
      setAge([ageRange.start, ageRange.end]);
    }
  }, [data.target]);

  useEffect(() => {
    setSearchParams({
      ...data,
      target: encodeURIComponent(
        JSON.stringify({
          gender,
          ageRange: {
            start: age[0],
            end: age[1],
          },
          styles,
        })
      ),
    });
  }, [gender, age, styles]);

  return (
    <Container>
      <Header
        title={t("Schedule.labelTarget")}
        customGoBack={() => navigate(`..${location.search}`)}
      />
      <Body>
        <TargetOptions
          gender={gender}
          setGender={setGender}
          styles={styles}
          setStyles={handleSelectStyles}
          age={age}
          setAge={setAge}
        />
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${veryVeryLightGrey};
`;
const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default OptionsTargetScreen;
