// @ts-nocheck
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  PlusOutlined,
  SearchOutlined,
  FilterOutlined
} from '@ant-design/icons';
import { Modal } from 'antd';

import { useApp } from '../../store';
import { grey, lightgray, white } from '../../config/colors';
import { getMoodFromRange } from '../../utils/functions';
import { useAllMedias } from '../../hooks';
// import components
import Tag from '../atoms/Tag';
import Title from '../atoms/Title';
import Text from '../atoms/Text';
import LibraryRow from '../molecules/LibraryRow';
import ModalNewCalendar from '../molecules/ModalNewCalendar';   

interface Props {};

const LibrarySearch: React.FC<Props> = props => {

  const [ t ] = useTranslation();
  const navigate = useNavigate();

  const [modalConfirmVisible, setModalConfirmVisible] = useState<boolean>(false);
  const [newCalendarInput, setNewCalendarInput] = useState<boolean>(false);

  const {
    setSearch,
    setSelectedTag,
    setFilterAlpha,
    refetchMedias,
    search,
    selectedTag,
    filterAlpha,
    listToDisplay,
    listToDisplaySearch
  } = useAllMedias(['schedule', 'station', 'playlist']);

  return (
    <Container>
      <Header>
        <Title bold>{t('Library.titleLibrary')}</Title>
        <PlusOutlined style={{ cursor: 'pointer', fontSize: 28}} onClick={() => setModalConfirmVisible(true)}/>
      </Header>
      <Body>
        <WrapperInput>
          <SearchOutlined
            style={{
              position: 'absolute',
              left: 10,
              top: 10,
              fontSize: 20,
            }}
          />
          <Input
            type='text'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </WrapperInput>
        <WrapperFilters>
          <WrapperTag>
            <ButtonTag onClick={() => setSelectedTag(t => t === 'stations' ? null : 'stations')}>
              <Tag bold={selectedTag === 'stations'} type={selectedTag === 'stations' ? 'selected': 'medias'}>{t('Settings.labelTagStations')}</Tag>
            </ButtonTag>
            <ButtonTag onClick={() => setSelectedTag(t => t === 'playlists'? null : 'playlists')}>
              <Tag bold={selectedTag === 'playlists'} type={selectedTag === 'playlists' ? 'selected': 'medias'}>{t('Settings.labelTagPlaylists')}</Tag>
            </ButtonTag>
            <ButtonTag onClick={() => setSelectedTag(t => t === 'schedules' ? null : 'schedules')}>
              <Tag bold={selectedTag === 'schedules'} type={selectedTag === 'schedules' ? 'selected': 'medias'}>{t('Settings.labelTagSchedules')}</Tag>
            </ButtonTag>
          </WrapperTag>
          <ButtonFilter
            onClick={() => setFilterAlpha(b => !b)}
          >
            <FilterOutlined style={{ fontSize: 20}} />
          </ButtonFilter>
        </WrapperFilters>
        <WrapperOption>
          {listToDisplaySearch !== null && listToDisplaySearch.length === 0 && (
            <Row>
              <Text>{t('Library.noResult')}</Text>
            </Row>
          )}
          {(listToDisplaySearch !== null ? listToDisplaySearch : listToDisplay).map((media, i) => {
            let params;
            let url;
            switch (media.type) {
              case 'station':
                url = 'station';
                // @ts-ignore
                params = new URLSearchParams({
                  id: media.id,
                  name: media.name,
                  mood: JSON.stringify(getMoodFromRange(media.bpmRange as number[])),
                  genre: JSON.stringify(media.genres),
                  target: JSON.stringify(media.targetGroup),
                });
                break;
              case 'schedule':
                url = '/calendar';
                // @ts-ignore
                params = new URLSearchParams({
                  idCalendar: media.id,
                });
                break;
              case 'playlist':
                url = 'playlist';
                // @ts-ignore
                params = new URLSearchParams({
                  media: JSON.stringify(media),
                });
                break;
            }
            return (
              <Link key={`${i}-media`} to={`${url}?${params.toString()}`}>            
                <LibraryRow
                  media={media}
                  type={media?.type || 'station'}
                />
              </Link>
            );
          })}
        </WrapperOption>
      </Body>
      <Modal
        title=""
        visible={modalConfirmVisible}
        onCancel={() => setModalConfirmVisible(false)}
        footer={null}
        bodyStyle={{ backgroundColor: "#434343"}}
      >
        <ModalContainer>
          <ModalBody>
            <ModalTitle>
              <Text color={white}>{`${t('Modal.modalTitleNewMedia')} :`}</Text>
            </ModalTitle>
            <ModalOption
              onClick={() => {
                navigate('Station', {
                  screen: 'StationOptions'
                });
                setModalConfirmVisible(false);
              }}
            >
              <Text size='large' color={white} bold>{t('Settings.stationLabel')}</Text>
            </ModalOption>
            <ModalOptionColumn>
              <ModalOption
                onClick={() => setNewCalendarInput(true)}>
                <Text size='large' color={white} bold>{t('Schedule.scheduleTitle')}</Text>
              </ModalOption>
              {newCalendarInput && (
                <ModalNewCalendar
                  closeModal={() => setModalConfirmVisible(false)}
                  refetch={refetchMedias}
                  closeInput={() => setNewCalendarInput(false)}
                />
              )}
            </ModalOptionColumn>
            <ModalOptionDisabled disabled>
              <Text color={grey} size='large' bold>{t('Settings.playlistLabel')}</Text>
              <Text color={grey} size='large' bold style={{ marginLeft: 5 }}>{`(${t('Settings.labelComingSoon')})`}</Text>
            </ModalOptionDisabled>
          </ModalBody>
        </ModalContainer>
      </Modal>
    </Container>
  );
}



const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 60px;
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
`;
const Body = styled.div`
  width: 100%;
  padding: 0 20px 20px 20px;
  align-items: center;
`;
const WrapperOption = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 40px;
`;
const WrapperFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;
const WrapperTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ButtonTag = styled.div`
  cursor: pointer;
`;
const Row = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ButtonRow = styled.div`
  cursor: pointer;
`;
const WrapperInput = styled.div`
  width: 100%;
  position: relative;
  margin-top: 20px;
`;
const Input = styled.input`
  width: 100%;
  border: solid 1px ${lightgray};
  border-radius: 7px;
  height: 40px;
  padding: 5px 10px;
  padding-left: 40px;
`;
const ButtonFilter = styled.div`
  border: 1px solid ${lightgray};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 3px 5px;
  border-radius: 10px;
  cursor: pointer;
`;
const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 300px;
`;
const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 20px 0 20px;
`;
const ModalTitle = styled.div`
  margin-bottom: 35px;
`;
const ModalOption = styled.div`
  width: 100%;
  margin-bottom: 20px;
  flex-direction: row;
  cursor: pointer;
`;
const ModalOptionDisabled = styled.div`
  width: 100%;
  margin-bottom: 20px;
  flex-direction: row;
`;
const ModalOptionColumn = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
export default LibrarySearch;