import {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import { black, purple} from '../../config/colors';
import {TypeMedia, MoodLabel} from '../../services/SoundSuitServiceTypes';
import {listGenres, idToLabel, IdToLabel} from '../../config/hardData';
import {getMoodFromRange} from '../../utils/functions';
// import components
import Text from '../atoms/Text';
import MultipleImagesThumbnail from '../atoms/MultipleImagesThumbnail';
import Tag from '../atoms/Tag';

type Props = {
  type: TypeMedia;
  media: any;
  isSmall: boolean;
  isVerySmall: boolean;
};

const StationCardCalendar = (props: Props) => {

  const {media, type, isSmall, isVerySmall} = props;

  const [t] = useTranslation();

  const labelsType = useRef({
    station: t('Settings.stationLabel'),
  }).current;

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;

  const [images, setImages] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [tagsMood, setTagsMood] = useState<(MoodLabel | undefined)[]>([]);
  const [labelBpm, setLabelBpm] = useState<string>('');

  useEffect(() => {
    if (media && Array.isArray(media.genres)) {
      const tgs = (media.genres as string[]).reduce<string[]>((acc, g, i) => {
        if (i === 1) {
          acc.push('...');
        } else if (i > 1) {
          return acc;
        } else {
          acc.push(g);
        }
        return acc;
      }, []);
      setTags(tgs);
    }
    if (media && media.bpmRange) {
      const moods = getMoodFromRange(media.bpmRange);
      setLabelBpm(`${media.bpmRange[0]}-${media.bpmRange[1]} bpm`);
      setTagsMood(moods);
    }
    if (media && Array.isArray(media.genres)) {
      const imgs = media.genres.map((genre: string) => listGenres.find(lg => lg.label === genre)?.img);
      setImages(imgs);
    }
  }, [media]);


  return (
    <ContainerMedia>
        <WrapperImage>{images.length > 0 && <MultipleImagesThumbnail imgs={images} size="small" />}</WrapperImage>
        <MainColumn>
          <WrapperTitle>
            <Text
              color={black}
              size="normal"
              bold
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {media?.name}
            </Text>
          </WrapperTitle>
          {!isVerySmall && (
            <>
              <Text color={purple} size="xsmall">
                {`${labelsType[media.type]} • ${labelBpm}`}
              </Text>
              <WrapperTags>
                {tagsMood.map((g: string, i: number) => {
      
                  if (g === '...') {
                    return (
                      <Tag
                        key={`${i}-${g}`}
                        type="mood"
                        size="small"
                      >
                        {g}
                      </Tag>
                    );
                  }
      
                  return (
                    <Tag
                      key={`${i}-${g}-mood`}
                      type="mood"
                      size="small"
                    >
                        {currentIdToLabel[g]}
                    </Tag>
                  );
                })}
              </WrapperTags>
              {!isSmall && (
                <WrapperTags>
                  {tags.map((g: string, i: number) => {

                    if (g === '...') {
                      return (
                        <Tag
                          key={`${i}-${g}`}
                          type="genre"
                          size="small"
                        >
                          {g}
                        </Tag>
                      );
                    }

                    return (
                      <Tag
                        key={`${i}-${g}-mood`}
                        type="genre"
                        size="small"
                      >
                          {currentIdToLabel[g]}
                      </Tag>
                    );
                  })}
                </WrapperTags>
              )} 
            </>
          )}
        </MainColumn>
    </ContainerMedia>
  );
};

const ContainerMedia = styled.div`
  z-index: 0;
`;
const MainColumn = styled.div`
  width: 100%;
`;
const WrapperImage = styled.div`
  width: 55px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
`;
const WrapperTitle = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const WrapperTags = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  margin-bottom: 5px;
`;

export default StationCardCalendar;
