import { useState, useEffect } from "react";
import { useQuery } from "react-query";
// import { useFocusEffect } from '@react-navigation/native';

import { usePlayer } from "../store";
import {
  getZoneById,
  getStations,
  getCalendars,
  getPlaylists
} from "../services/SoundSuitService";
import type { Station, Calendar, TypeMedia, PlaylistItem } from "../services/SoundSuitServiceTypes"; 

function useAllMedias(medias: TypeMedia[]) {

  async function refetchMedias() {
    if (medias.includes('station')) {
      await refetchStations();
    }
    if (medias.includes('schedule')) {
      await refetchCalendars();
    }
    if (medias.includes('playlist')) {
      await refetchPlaylists();
    }
  }

  const zoneSelected = usePlayer(statePlayer => statePlayer.state.zoneSelected);

  const { data: dataZone, refetch: refetchZone } = useQuery(['zone', zoneSelected], () => {
    if (zoneSelected) {
      return getZoneById(zoneSelected)
    }
  });
  const { data: dataStations, refetch: refetchStations, isFetching: fetchingStations  } = useQuery('stations', getStations);
  const { data: dataCalendars, refetch: refetchCalendars, isFetching: fetchingCalendars } = useQuery('calendars', getCalendars);
  const { data: dataPlaylists, refetch: refetchPlaylists, isFetching: fetchingPlaylists } = useQuery('playlists', getPlaylists);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [listStations, setListStations] = useState<Station[]>([]);
  const [listCalendars, setListCalendars] = useState<Calendar[]>([]);
  const [listPlaylists, setListPLaylists] = useState<PlaylistItem[]>([]);

  const [listToDisplay, setListToDisplay] = useState<(Station | Calendar | PlaylistItem)[]>([]);
  const [listToDisplaySearch, setListToDisplaySearch] = useState<(Station | Calendar | PlaylistItem)[] | null>(null);

  const [search, setSearch] = useState<string>("");
  const [selectedTag, setSelectedTag] = useState<'stations'|'playlists'|'schedules'|null>(null);
  const [filterAlpha, setFilterAlpha] = useState<boolean>(false);

  useEffect(() => {
    if (fetchingCalendars || fetchingStations || fetchingPlaylists) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
    }
  }, [fetchingCalendars, fetchingStations, fetchingPlaylists]);

  useEffect(() => {
    if (listToDisplaySearch !== null) {
      if (!filterAlpha) {
        setListToDisplaySearch(list => list.sort((a, b) => (a?.name || '').localeCompare((b?.name || ''))));
      } else {
        setListToDisplaySearch(list => list.sort((a, b) => (b?.name || '').localeCompare((a?.name || ''))));
      }
    } else {
      if (!filterAlpha) {
        setListToDisplay(list => list.sort((a, b) => (a?.name || '').localeCompare((b?.name || ''))));
      } else {
        setListToDisplay(list => list.sort((a, b) => (b?.name || '').localeCompare((a?.name || ''))));
      }
    }
  }, [filterAlpha]);

  useEffect(() => {
    if (!isFetching && dataZone) {
      // for Stations
      const keyMediaS = `authorizedStations`;
      // @ts-ignore
      const authorizedIdsS = dataZone[keyMediaS];
      if (dataStations && dataStations.length > 0) {
        const list = dataStations.filter(s => authorizedIdsS.includes(s.id));
        setListStations(list.map(l =>({...l, type: 'station'})));
      }
      // for Playlists
      const keyMediaP = `authorizedPlaylists`;
      // @ts-ignore
      const authorizedIdsP = dataZone[keyMediaP];
      if (dataPlaylists && dataPlaylists.length > 0) {
        const list = dataPlaylists.filter(s => authorizedIdsP.includes((s?.id || s?._id)));
        setListPLaylists(list.map(l =>({...l, id: l._id, type: 'playlist'})));
      }
      // for Schedules
      const keyMediaC = `authorizedSchedules`;
      // @ts-ignore
      const authorizedIdsC = dataZone[keyMediaC];
      if (dataCalendars && dataCalendars.length > 0) {
        const list = dataCalendars.filter(s => authorizedIdsC.includes(s.id));
        // setListCalendars(list);
        // TEMP REMOVE WHEN ADD TO AUTHORIZED
        setListCalendars(dataCalendars.map(c => ({...c, type: 'schedule'})));
      }
    }
  }, [dataZone, dataStations, dataCalendars, dataPlaylists, isFetching]);

  // useFocusEffect(
  //   useCallback(() => {
  //     async function refetch() {
  //       await refetchZone();
  //       await refetchStations();
  //       await refetchCalendars();
  //       await refetchPlaylists();
  //     }
  //     refetch();
  //   }, [])
  // );

  useEffect(() => {
    if (!isFetching) {
      let list: any[] = [];
      if (medias.includes('station')) {
        list = [...list, ...listStations];
      }
      if (medias.includes('schedule')) {
        list = [...list, ...listCalendars];
      }
      if (medias.includes('playlist')) {
        list = [...list, ...listPlaylists];
      }
      setListToDisplay(list);

      // If search active and selectedTag active or not
      if (search) {
        var regexSearch = new RegExp(search, 'gi');
        let searchList = list;
  
        if (selectedTag) {
          switch (selectedTag) {
            case 'stations':
                searchList = listStations;
              break;
            case 'schedules':
                searchList = listCalendars;
              break;
            case 'playlists':
                searchList = listPlaylists;
              break;
          }
        }
  
        const rslt = searchList.reduce<(Station | Calendar | PlaylistItem)[]>((acc, m) => {
          // @ts-ignore
          const toTest = `${m.name} ${m?.description || ''}`;
          if (regexSearch.test(toTest)) {
            acc.push(m);
          }
          return acc;
        }, []);
        setListToDisplaySearch(rslt);

      } else if (selectedTag) {
        switch (selectedTag) {
          case 'stations':
              setListToDisplaySearch(listStations);
            break;
          case 'schedules':
              setListToDisplaySearch(listCalendars);
            break;
          case 'playlists':
              setListToDisplaySearch(listPlaylists);
            break;
          default:
              setListToDisplaySearch(null);
            break;
        }
      } else {
        setListToDisplaySearch(null);
      }
    }
  }, [listStations, listCalendars, listPlaylists, isFetching, selectedTag, search]);

  return {
    setSearch,
    setSelectedTag,
    setFilterAlpha,
    refetchMedias,
    search,
    selectedTag,
    filterAlpha,
    listToDisplay,
    listToDisplaySearch
  };
}

export default useAllMedias;