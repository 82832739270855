import React from 'react';
import styled from 'styled-components';
import {
  Routes,
  Route,
} from "react-router-dom";

import { veryVeryLightGrey } from '../../config/colors';
// import components
import StationOption from './StationOptions';
import OptionsMoodScreen from './OptionsMoodScreen';
import OptionsGenreScreen from './OptionsGenreScreen';
import OptionsTargetScreen from './OptionsTargetScreen';

interface Props {};

const StationScreen: React.FC<Props> = props => {

  return (
    <Container>
      <Routes>
        <Route index element={<StationOption />} />
        <Route path="mood" element={<OptionsMoodScreen />} />
        <Route path="genre" element={<OptionsGenreScreen />} />
        <Route path="target" element={<OptionsTargetScreen />} />
      </Routes>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${veryVeryLightGrey};
`;

export default StationScreen;