import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Outlet, useSearchParams, useNavigate } from "react-router-dom";

import {
  getTimeSlots,
  getCalendar
} from '../../services/SoundSuitService';
import { usePlayer, useApp } from '../../store';
import {
  useSchedule,
  useMigrateSlots
} from '../../hooks';
// import components
import HeaderSchedule from '../molecules/HeaderSchedule';
import CalendarTablet from '../molecules/CalendarTablet';

export interface ParamsCalendar {
  idCalendar?: string
}

interface Props {};

const ScheduleScreen: React.FC<Props> = props => {

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dataParams = Object.fromEntries([...params]);

  const {
    idCalendar,
  } = dataParams as ParamsCalendar;

  const { data, isLoading } = useQuery(['calendar-timeslots', idCalendar],
    () => getTimeSlots(idCalendar)
    , {
      onError: error=> {
        console.log('ERROR REQUETE SCHEDULE: ', error)
      }
  });

  const { data: dataCalendar } = useQuery(['calendar-info', idCalendar],
    () => getCalendar(idCalendar)
    , {
      onError: error=> {
        console.log('ERROR REQUETE SCHEDULE: ', error)
      }
  });

  const globalRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [widthColumn, setWidthColumn] = useState(0);
  const [name, setName] = useState<string>('');
  

  const menuOn = useApp(state => state.state.menuOn);

  const { generateRecurrentSlots } = useSchedule();
  const { migrateSlots } = useMigrateSlots();
  const setTimeslots = usePlayer.getState().reducers.setTimeslots;
  const scheduleDaySelected = usePlayer(statePlayer => statePlayer.state.scheduleDaySelected);

  useEffect(() => {
    if (!idCalendar) {
      navigate('/library');
    }
  }, [idCalendar]);

  useEffect(() => {
    if (Array.isArray(data)) {
      const migratedSlots = migrateSlots(data);
      const recurrentProgram = generateRecurrentSlots(migratedSlots);
      setTimeslots(recurrentProgram);
    }
  }, [data]);

  useEffect(() => {
    if (dataCalendar) {
      setName(dataCalendar.name);
    }
  }, [dataCalendar]);

  useEffect(() => {
    if (!globalRef.current || globalRef.current === null) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if(globalRef.current && globalRef.current.offsetWidth !== width) {
        setWidth(globalRef.current.offsetWidth); 
      }
    });
    resizeObserver.observe(globalRef.current);

    return function cleanup() {
      resizeObserver.disconnect();
    }
  }, [globalRef.current]);

  useEffect(() => {
    if(width !== 0) {
      setWidthColumn((width - (menuOn ? 90 : 0)) / 8);
    }
  }, [width, menuOn]);

  return (
    <>
      <GlobalWrapper
        ref={globalRef}
      >
        <Container style={{ width: `${width - (menuOn ? 90 : 0)}px` }}>
          <HeaderSchedule day={scheduleDaySelected} idCalendar={idCalendar} name={name} />
          <WrapperCalendar>
            <CalendarTablet widthColumn={widthColumn} loading={isLoading} idCalendar={idCalendar } />
          </WrapperCalendar>
        </Container>
        <Outlet />
      </GlobalWrapper>
    </>
  );
}

const GlobalWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-end;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const WrapperCalendar = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-top: 10px;
  overflow: hidden;
`;

export default ScheduleScreen;