import React, { useMemo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { notification, Modal } from 'antd';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { TailSpin } from 'react-loading-icons';
import {
  RightOutlined
} from '@ant-design/icons';
import {
  isBefore,
  isAfter,
  addHours,
  differenceInHours,
  addSeconds,
  subSeconds,
  subHours,
} from 'date-fns';
import WheelPicker from 'react-simple-wheel-picker';

import {
  veryVeryLightGrey,
  veryLightLightGrey,
  redText,
  almostWhite,
  grey
} from '../../config/colors';

import { OptionsDetails } from '../../components/molecules/ListOptions';
import {  Days, ItemSchedule } from '../../services/SoundSuitServiceTypes';
import { idToLabel, IdToLabel} from '../../config/hardData';
import {
  setTimeSlot,
  updateTimeSlot,
  deleteTimeSlot,
} from '../../services/SoundSuitService';
import { usePlayer } from '../../store';
import {
  transformHourStringtoDate,
  transformDatetoHourString
} from '../../utils/functions';
import { useSchedule, useAllMedias } from '../../hooks';
// import components
import Text from '../../components/atoms/Text';
import Header from '../molecules/Header';
import ListOptions from '../molecules/ListOptions';
import TimePicker from '../atoms/TimePicker';
import MediaRow from '../molecules/MediaRow';

type IsValid = 'valid'|'startNoValid'|'endNoValid'|'notAvailable'| 'minOneHour';

export interface ParamsScheduleItem {
  id?: string;
  originalDay?: string;
  day?: Days;
  startDate?: Date | string;
  endDate?: Date | string;
  days?: string | undefined;
  mood?: string | undefined;
  genre?: string | undefined;
  target?: any | undefined;
  startLimit?: Date | string;
  endLimit?: Date | string;
  idCalendar?: string;
  idMedia?: string;
}

interface Props {};

const ScheduleItem:React.FC<Props> = props => {

  function resetRepeatDays(d: Days) {
    setDays([d]);
    setSearchParams({
      ...dataParams,
      days: encodeURIComponent(JSON.stringify([d]))
    })
  }

  function returnRightError(isValid: IsValid):string {
    switch (isValid) {
      case 'startNoValid':
        return t('Schedule.errorStartNoValid');
      case 'endNoValid':
        return t('Schedule.errorEndNoValid');
      case 'notAvailable':
        return t('Schedule.errorNotAvailable');
      case 'minOneHour':
        return t('Schedule.errorMinOneHour');
      default:
        return "";
    }
  }


  async function handleSave(): Promise<void> {

    const isValid = checkTimeSlotAvailable(timeslots, day, id, startDate, endDate);

    if (isValid !== 'valid') {
      Modal.error({
        title: t('Schedule.alertTitle'),
        content: returnRightError(isValid),
      });
    } else {
      const arg: ItemSchedule = {
        timeSlot: {
          start: {day, hour: transformDatetoHourString(startDate)},
          end: {day, hour: transformDatetoHourString(endDate)},
          timezone
        },
        days,
        playlist: mediaSelected.type === 'playlist' ? mediaSelected.id : null,
        station: mediaSelected.type === 'station' ? mediaSelected.id : null,
      };
      // if id, means its an update
      if (id) {
        await updateItem({id, data: arg});
        toggleRefreshTracks();
      } else {
        await sendItem({idCalendar: idCalendar, data: arg});
        toggleRefreshTracks();
      }
    }
  }

  async function handleDelete(): Promise<void> {
    try {
      await deleteItem(id as string);
    } catch (error) {
      console.log("ERROR : ", error);
    }
  }

  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    listToDisplay
  } = useAllMedias(['playlist', 'station']);

  const [params, setSearchParams] = useSearchParams();
  const dataParams = Object.fromEntries([...params]);

  const {
    id,
    idCalendar,
    day: dayPost,
    originalDay,
    startDate: startDatePost,
    endDate: endDatePost,
    days: daysPost,
    startLimit,
    endLimit,
    idMedia: idMediaPost,
  } = dataParams as ParamsScheduleItem;

  const location = useLocation();

  const queryClient = useQueryClient();

  const { mutateAsync: sendItem, isLoading: isLoadingCreate } = useMutation(setTimeSlot, {
    onSuccess: () => {
      queryClient.invalidateQueries(['calendar-timeslots', idCalendar]);
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
      navigate('/calendar?idCalendar=' + idCalendar);
    },
    onError: (error) => {
      console.log("ERROR: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });
  const { mutateAsync: updateItem, isLoading: isLoadingUpdate } = useMutation(updateTimeSlot, {
    onSuccess: () => {
      queryClient.invalidateQueries(['calendar-timeslots', idCalendar]);
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
      navigate('/calendar?idCalendar=' + idCalendar);
    },
    onError: (error) => {
      console.log("ERROR: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });
  const { mutateAsync: deleteItem, isLoading: isLoadingDelete } = useMutation(deleteTimeSlot, {
    onSuccess: () => {
      queryClient.invalidateQueries(['calendar-timeslots', idCalendar]);
      notification.open({
        message: '',
        description: t('Notifications.requestSuccess'),
      });
      navigate('/calendar?idCalendar=' + idCalendar);
    },
    onError: (error) => {
      console.log("ERROR: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  });

  const { checkTimeSlotAvailable } = useSchedule();

  const timezone = useRef(Intl.DateTimeFormat().resolvedOptions().timeZone).current;

  const toggleRefreshTracks = usePlayer.getState().reducers.toggleRefreshTracks;

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;
  const timeslots = usePlayer(statePlayer => statePlayer.state.timeslots);

  const [day, setDay] = useState<Days>((typeof originalDay !== 'undefined' && originalDay !== 'undefined') ? originalDay as Days : (dayPost ? dayPost : 'monday'));
  const [startDate, setStartDate] = useState<Date>(startDatePost ? transformHourStringtoDate(startDatePost as string) : transformHourStringtoDate('10:00:00'));
  const [endDate, setEndDate] = useState<Date>(endDatePost ? transformHourStringtoDate(endDatePost as string) : transformHourStringtoDate('12:00:00'));
  const [days, setDays] = useState<Days[]>(daysPost ? JSON.parse(decodeURIComponent(daysPost)) : [day]);

  const [idMedia, setIdMedia] = useState<string|undefined>(idMediaPost ? idMediaPost: undefined);
  const [mediaSelected, setMediaSelected] = useState<any | undefined>(undefined);

  const[warningStart, setWarningStart] = useState<boolean>(false);
  const[warningEnd, setWarningEnd] = useState<boolean>(false);

  useEffect(() => {
    if (idMedia) {
      const m = listToDisplay.find(m => m.id === idMedia);
      if (m) {
        setMediaSelected(m);
      }
    }
  }, [idMedia, listToDisplay]);

  const dataOptions: OptionsDetails[] = useMemo(() => ([
    {
      label: (typeof originalDay !== 'undefined' && originalDay !== 'undefined') ? t('Schedule.recurrentEventTitle') : t('Schedule.labelDay'),
      selection: `${(typeof originalDay !== 'undefined' && originalDay !== 'undefined') ? `${t('Schedule.recurrentEventLabel')}: ` : ""} ${day ? t(`Schedule.${day}`) : ""}`,
      onPressType: 'slot',
      componentToDisplay:
        <WheelPicker
          data={[
            { id: 'monday', value: t('Schedule.monday') as string},
            { id: 'tuesday', value: t('Schedule.tuesday') as string},
            { id: 'wednesday', value: t('Schedule.wednesday') as string},
            { id: 'thursday', value: t('Schedule.thursday') as string},
            { id: 'friday', value: t('Schedule.friday') as string},
            { id: 'saturday', value: t('Schedule.saturday') as string},
            { id: 'sunday', value: t('Schedule.sunday') as string},
          ]}
          onChange={({id}) => {
            setDay(id as Days);
            resetRepeatDays(id as Days);
          }}
          height={180}
          itemHeight={30}
          selectedID={day}
          color="#ccc"
          activeColor="#333"
          backgroundColor="#fff"
          shadowColor="none"
          focusColor="none"
        />
    },
    {
      label: t('Schedule.labelStartTime'),
      selection: 
        <WrapperTimePicker>
          <TimePicker
            className='antd-timepicker-custom'
            value={startDate}
            format={'HH:mm'}
            allowClear={false}
            bordered={false}
            minuteStep={1}
            showNow={false}
            onSelect={(value) => {
              if(isBefore(value, subSeconds(endDate, 10))) {
                setStartDate(value);
                setWarningStart(false);
                resetRepeatDays(day);
              } else if(endLimit) {
                if(isAfter(value, subSeconds(endDate, 10)) && differenceInHours(transformHourStringtoDate(endLimit as string), endDate, {roundingMethod: 'floor'}) >= 1 ) {
                  const newEndDate = addHours(value, 1);
                  setEndDate(newEndDate);
                  setStartDate(value);
                  setWarningStart(false);
                  resetRepeatDays(day);
                } else {
                  setStartDate(subHours(transformHourStringtoDate(endLimit as string), 1));
                  setWarningStart(true);
                  resetRepeatDays(day);
                }
              } else {
                if(isAfter(value, subSeconds(endDate, 10))){
                  const newEndDate = addHours(value, 1);
                  setEndDate(newEndDate);
                  setStartDate(value);
                  setWarningStart(false);
                  resetRepeatDays(day);
                }
              }
            }}
          />
        </WrapperTimePicker>,
    },
    {
      label: t('Schedule.labelEndTime'),
      selection: 
      <WrapperTimePicker>
        <TimePicker
          className='antd-timepicker-custom'
          value={endDate}
          format={'HH:mm'}
          allowClear={false}
          bordered={false}
          minuteStep={1}
          showNow={false}
          onSelect={value => {
            if(isAfter(value, addSeconds(startDate, 10))) {
              setEndDate(value);
              setWarningEnd(false);
              resetRepeatDays(day);
            } else if(startLimit) {
              if (isBefore(value, addSeconds(startDate, 10)) && isBefore(addSeconds(transformHourStringtoDate(startLimit as string), 10), startDate)) {
                const newStartDate = subHours(value, 1);
                setStartDate(newStartDate);
                setEndDate(value);
                setWarningEnd(false);
                resetRepeatDays(day);
              } else {
                setEndDate(addHours(transformHourStringtoDate(startLimit as string), 1));
                setWarningEnd(true);
                resetRepeatDays(day);
              }
            } else {
              if(isBefore(value, addSeconds(startDate, 10))) {
                const newStartDate = subHours(value, 1);
                setStartDate(newStartDate);
                setEndDate(value);
                setWarningEnd(false);
                resetRepeatDays(day);
              }
            }
          }}
        />
      </WrapperTimePicker>,
    },
    {
      label: t('Schedule.labelRepeat'),
      selection: days.reduce((acc, d, i) =>  {
        if (i <= 2) {
          acc.push(t(`Schedule.${d}`));
        }
        return acc;
      }, []).join(', ') + (days.length > 3 ? ', ...' : ''),
      onPressType: 'link',
      onPressOption: () => navigate(`repeat${location.search}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ]), [
      day,
      startDate,
      endDate,
      days,
      startLimit,
      endLimit,
      warningStart,
      warningEnd,
      originalDay,
      t,
      currentIdToLabel,
      navigate,
      location
    ]);

  useEffect(() => {
    if (day || startDate || endDate || idMedia) {
      setSearchParams({
        ...dataParams,
        day,
        startDate: transformDatetoHourString(startDate),
        endDate: transformDatetoHourString(endDate),
        idMedia,
      });
    }
  }, [day, startDate, endDate, idMedia]);

  return (
    <Container>
      <Header
        title={t('Schedule.scheduleItemTitle')}
        handlePressSave={handleSave}
        inProgress={isLoadingCreate || isLoadingUpdate}
        customGoBack={() => navigate('/calendar?idCalendar=' + idCalendar)}
      />
      <Body contentContainerStyle={{alignItems: 'center', paddingBottom: 60}}>
        <ListOptions data={dataOptions} heightSlot={220} />
        <MusicBloc>
          <MusicRow
            onClick={() => navigate(`music-choice${location.search}`)}
          >
            <Text>{t('Settings.MusicLabel')}</Text>
            <div
                style={{
                  position: 'absolute',
                  right: 15,
                }}
              >
                <RightOutlined style={{ fontSize: 24 }} />
            </div>
          </MusicRow>
          {mediaSelected && (
            <WrapperMusicSelection>
              <MediaRow
                media={mediaSelected}
                type={mediaSelected.type}
                isSelected={false}
                useFor='calendar'
              />
            </WrapperMusicSelection>
          )}
        </MusicBloc>
        {id && !isLoadingDelete && (
          <Button onClick={handleDelete}>
            <Text bold color={redText}>
              {t('Schedule.scheduleDeleteItem')}
            </Text>
          </Button>
        )}
        {id && isLoadingDelete && (
          <WrapperLoading>
            <TailSpin stroke={redText} height="2em" />
          </WrapperLoading>
        )}
      </Body>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${veryVeryLightGrey};
`;
const Body = styled.div`
  width: 100%;
  padding: 0 15px 0 15px;
`;
const Button = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${almostWhite};
  border-color: ${veryLightLightGrey};
  border-width: 1px;
  border-radius: 10px;
  margin-top: 40px;
  padding: 15px;
  cursor: pointer;
`;
const WrapperLoading = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${almostWhite};
  border-color: ${veryLightLightGrey};
  border-width: 1px;
  border-radius: 10px;
  margin-top: 40px;
  padding: 15px;
`;
const MusicBloc = styled.div`
  width: 100%;
  height: 210px;
  background-color: ${almostWhite};
  border-radius: 10px;
  margin-top: 25px;
  padding: 10px 0 10px 0px;
  border-width: 1px;
  border-color: ${veryLightLightGrey};
`;
const MusicRow= styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-color: ${veryLightLightGrey};
  border-bottom-width: ${({isLast}) => isLast ? '0' : '1px'};
  cursor: pointer;
`;
const WrapperMusicSelection = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 10px 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const WrapperTimePicker = styled.div`
  width: 100px;
`;

export default ScheduleItem;