import styled from 'styled-components';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loading-icons'

import {setCalendar, loadUserProfile, getZoneById, updateAuthorizedSchedulesToZone} from '../../services/SoundSuitService';
import { grey } from '../../config/colors';
// import components
import Text from '../atoms/Text';

type Props = {
  closeModal: () => void;
  refetch: () => void;
  closeInput: () => void;
};

const ModalNewCalendar = (props: Props) => {

  async function handleCreateCalendar() {
    if (name) {
      try {
        const res = await sendCalendar({name});
        // TMP: WE AUTHORIZED THE NEW SCHEDULE FOR ALL THE ZONES
        if (dataUser?.zones) {
          for (let i = 0; i < dataUser?.zones.length; i++) {
            try {
              const id = dataUser?.zones[i];
              const zone = await getZoneById(id);
              await updateAuthorizedSchedulesToZone(id, [...zone.authorizedSchedules, res._id]);
            } catch (error) {
              console.log("ERROR :", error);
            }
          }
        }
        await refetch();
        closeModal();
      } catch (error) {
        console.log("ERROR : ", error)
      }
    }
  }

  const { closeModal, refetch, closeInput } = props;
  const [ t ] = useTranslation();
  const [name, setName] = useState<string>("");

  const { data: dataUser } = useQuery('/userinfo', loadUserProfile);

  const {mutateAsync: sendCalendar, isLoading: isLoadingCreate} = useMutation(
    setCalendar,
    {
      onSuccess: () => {
        // showMessage({
        //   message: t('Notifications.requestSuccess'),
        //   type: 'info',
        // });
      },
      onError: error => {
        console.log('ERROR: ', error);
        // showMessage({
        //   message: t('Notifications.requestFailed'),
        //   type: 'warning',
        // });
      },
    },
  );

  return (
    <Container>
      <Text color={grey} size="xsmall" >{`${t('Schedule.labelNewCalendar')} :`}</Text>
      <Row>
        <Input
          type="text"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {isLoadingCreate && (
          <WrapperLoader>
             <TailSpin stroke="black" height="2em" />
          </WrapperLoader>
        )}
        {!isLoadingCreate && (
          <>
          <Button
            onClick={handleCreateCalendar}
          >
            <Text bold >OK</Text>
          </Button>
          <Button
            onClick={closeInput}
          >
            <Text bold >Cancel</Text>
          </Button>
          </>
        )}
      </Row>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 10px;
  padding-left: 20px;
`;
const Input = styled.input`
  background-color: white;
  border-radius: 5px;
  height: 40px;
  width: 60%;
  padding-left: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
`;
const Button = styled.div`
  margin-left: 20px;
  cursor: pointer;
  padding: 4px;
`;
const WrapperLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export default ModalNewCalendar;