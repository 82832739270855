import React from 'react';
import styled from 'styled-components';
import { Menu as MenuAntd } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logoSS from '../../assets/icons/logo.svg';
import logoLong from '../../assets/icons/soundsuit_logo_icon_trademark.svg';
import iconAccount from '../../assets/icons/shop.png';
import iconLibrary from '../../assets/icons/libraryActive.png';
import iconEqualizer from '../../assets/icons/equalizer_white.gif';
import { useApp } from '../../store';

interface Props {
  activeScreen: string;
  setActiveScreen: (index: string) => void;
}

const Menu: React.FC<Props> = props => {
  const { activeScreen, setActiveScreen } = props;

  const navigate = useNavigate();
  const menuOn = useApp(state => state.state.menuOn);
  const setMenuOn = useApp.getState().reducers.setMenuOn;

  const [ t ] = useTranslation();

  const getMenuItemStyle = (key: string) => ({
    color: 'white',
    fontSize: '16px',
    borderRadius: '10px',
    backgroundColor: activeScreen === key ? '#4A4B53' : 'transparent',
    padding: menuOn ? '0px 15px' : '5px 0px 0px 18px',
    margin: '10px 10px',
    width: 'auto',
    height: '55px',
  });

  return (
    <Container menuOn={menuOn}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button onClick={() => setMenuOn(!menuOn)}>
          <Logo alt="soundsuit-logo" src={menuOn ? logoLong : logoSS} menuOn={menuOn} />
        </Button>
        { menuOn &&
          <IconFoldMenu onClick={() => setMenuOn(!menuOn)} />
        }
      </div>
      <MenuAntd
        onClick={({ key }) => {
          setActiveScreen(key);
          switch (key) {
            case "1":
              navigate('/');
              break;
            case "2":
              navigate('library');
              break;
            case "3":
              navigate('account');
              break;
            default:
              break;
          }
        }}
        selectedKeys={[activeScreen]}
        mode="inline"
        theme="dark"
        inlineCollapsed={!menuOn}
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#262626",
        }}
      >
        <MenuAntd.Item key="1" title="" icon={<Icon src={iconEqualizer} menuOn={menuOn} />} style={getMenuItemStyle("1")}>
          {t('Player.PlayerNavTitle')}
        </MenuAntd.Item>
        <MenuAntd.Item key="2" title="" icon={<Icon src={iconLibrary} menuOn={menuOn} />} style={getMenuItemStyle("2")}>
          {t('Library.titleLibrary')}
        </MenuAntd.Item>
        <MenuAntd.Item key="3" title="" icon={<Icon src={iconAccount} menuOn={menuOn} />} style={getMenuItemStyle("3")}>
          {t('Settings.location')}
        </MenuAntd.Item>
      </MenuAntd>
    </Container>
  );
}

const Container = styled.div<{ menuOn: boolean }>`
  grid-column: 1 / 2;
  position: absolute;
  width: ${({ menuOn }) => (menuOn ? '170px' : '80px')};
  top: 0;
  left: 0;
  height: 100%;
  background-color: #262626;
  z-index: 50;
`;
const Button = styled.div`
  cursor: pointer;
  margin: 15px 0 15px 15px;
  width: 50px;
`;
const Logo = styled.img<{ menuOn: boolean }>`
  width: ${({ menuOn }) => (menuOn ? '110px' : '40px')};
  height: ${({ menuOn }) => (menuOn ? '40px' : '40px')};
  border-radius: 25px;
  margin-left: ${({ menuOn }) => (menuOn ? '0' : '5px')};
`;

const Icon = styled.img<{ menuOn: boolean }>`
  width: ${({ menuOn }) => (menuOn ? '20px' : '25px')};
  height: ${({ menuOn }) => (menuOn ? '20px' : '25px')};
  margin-right: ${({ menuOn }) => (menuOn ? '10px' : '0')};
  transition: width 0.3s, height 0.3s, margin-right 0.3s;
`;
interface IconProps extends React.SVGProps<SVGSVGElement> { }

const IconFoldMenu: React.FC<IconProps> = (props) => {
  return (
    <svg
      strokeLinejoin="round"
      strokeWidth={0}
      viewBox="0 0 16 16"
      stroke="currentColor"
      fill="currentColor"
      style={{
        width: '25px',
        height: '25px',
        marginRight: '15px',
        cursor: 'pointer'
      }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.245 2.5H14.5V12.5C14.5 13.0523 14.0523 13.5 13.5 13.5H6.245V2.5ZM4.995 2.5H1.5V12.5C1.5 13.0523 1.94772 13.5 2.5 13.5H4.995V2.5ZM0 1H1.5H14.5H16V2.5V12.5C16 13.8807 14.8807 15 13.5 15H2.5C1.11929 15 0 13.8807 0 12.5V2.5V1Z"
        fill="#6a6a6a"
      ></path>
    </svg >
  );
};

export default Menu;
