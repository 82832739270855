import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FilterOutlined,
  SearchOutlined
} from '@ant-design/icons';

import { lightgray, white } from '../../config/colors';
import { useAllMedias } from '../../hooks';
// import components
import Tag from '../../components/atoms/Tag';
import MediaRow from '../../components/molecules/MediaRow';
import Text from '../../components/atoms/Text';
import Header from '../molecules/Header';

interface Props {};

const MusicChoice: React.FC<Props> = props => {

  const [ t ] = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const [params, setSearchParams] = useSearchParams();
  const data = Object.fromEntries([...params]);

  const {
    idMedia: idMediaPost
  } = data;

  const [idMedia, setIdMedia] = useState<string | undefined>(idMediaPost ? idMediaPost: undefined);

  useEffect(() => {
    setSearchParams({
      ...data,
      idMedia
    });
  }, [idMedia, setSearchParams]);

  const {
    setSearch,
    setSelectedTag,
    setFilterAlpha,
    search,
    selectedTag,
    listToDisplay,
    listToDisplaySearch
  } = useAllMedias(['station', 'playlist']);

  return (
    <div>
      <Container>
        <Header
          title={t('Schedule.selectMusicLabel')}
          customGoBack={() => navigate(`..${location.search}`)}
        />
        <Body>
          <WrapperInput>
            <SearchOutlined
              style={{
                position: 'absolute',
                left: 10,
                top: 10,
                fontSize: 20,
              }}
            />
            <Input
              placeholder='Search'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </WrapperInput>
          <WrapperFilters>
            <WrapperTag>
              <Pressable onClick={() => setSelectedTag(t => t === 'stations' ? null : 'stations')}>
                <Tag bold={selectedTag === 'stations'} type={selectedTag === 'stations' ? 'selected': 'medias'}>{t('Settings.labelTagStations')}</Tag>
              </Pressable>
              <Pressable onClick={() => setSelectedTag(t => t === 'playlists'? null : 'playlists')}>
                <Tag bold={selectedTag === 'playlists'} type={selectedTag === 'playlists' ? 'selected': 'medias'}>{t('Settings.labelTagPlaylists')}</Tag>
              </Pressable>
            </WrapperTag>
            <ButtonFilter
              onClick={() => setFilterAlpha(b => !b)}
            >
              <FilterOutlined style={{ fontSize: 20}} />
            </ButtonFilter>
          </WrapperFilters>
          <WrapperOption>
            {listToDisplaySearch !== null && listToDisplaySearch.length === 0 && (
              <Row>
                <Text>{t('Library.noResult')}</Text>
              </Row>
            )}
            {(listToDisplaySearch !== null ? listToDisplaySearch : listToDisplay).map((media, i) => (
              <Pressable
                key={`${i}-media`}
                onClick={() => setIdMedia(media.id)}
              >
                <MediaRow
                  key={`${i}-media`}
                  media={media}
                  // @ts-ignore
                  type={media?.type || 'station'}
                  isSelected={media.id === idMedia}
                  useFor='calendar'
                />
              </Pressable>
            ))}
          </WrapperOption>
        </Body>
      </Container>
    </div>
  );
}



const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  flex: 1;
  background-color: white;
  border-radius: 10px;
`;
const Body = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const WrapperOption = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 40px;
`;
const WrapperFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;
const WrapperTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const WrapperInput = styled.div`
  width: 100%;
  position: relative;
  margin-top: 20px;
`;
const Input = styled.input`
  border: solid 1px ${lightgray};
  border-radius: 7px;
  height: 40px;
  padding: 5px 10px;
  padding-left: 40px;
  background-color: ${white};
  width: 100%;
  &:focus {
    outline: none;
  }
`;
const ButtonFilter = styled.div`
  border: 1px solid ${lightgray};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 3px 5px;
  border-radius: 10px;
  cursor: pointer;
`;
const Row = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Pressable = styled.div`
  cursor: pointer;
`;
export default MusicChoice;