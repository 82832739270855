import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMemo } from "react";
import styled from "styled-components";
import {
  differenceInSeconds
} from 'date-fns';
import { TailSpin } from 'react-loading-icons';

import type { ItemSchedule,Station, TypeMedia } from "../../services/SoundSuitServiceTypes";
import {
  getHeightItemSchedule,
  getMoodFromRange,
  getTopItemSchedule,
  transformHourStringtoDate
} from "../../utils/functions";
import { useAllMedias } from "../../hooks";
import { idToLabel, IdToLabel, listMoods, widthTrancheScheduleTablet } from "../../config/hardData";
import { grey, lightPurple, lightBlue } from "../../config/colors";
// import components
import Text from "../atoms/Text";
import MediaCardCalendar from "./MediaCardCalendar";

type BGColor = { [KEY in TypeMedia]: string };
// @ts-ignore
const bgColor: BGColor = {
  'station': lightPurple,
  'playlist': lightBlue,
};

interface Props {
  data: ItemSchedule;
  leftPos: number;
  widthColumn: number;
  startLimit: Date | string | undefined;
  endLimit: Date | string | undefined;
  idCalendar: string;
}

export default function ItemSchedule2(props: Props) {
  const {
    data,
    startLimit,
    endLimit,
    widthColumn,
    leftPos,
    idCalendar
  } = props;

  const location = useLocation();

  const [isSmall, setSmall] = useState<boolean>(false);
  const [isVerySmall, setVerySmall] = useState<boolean>(false);

  const [img, setImg] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [subTitle, setSubTitle] = useState<string | null>(null);

  const {listToDisplay} = useAllMedias(['station', 'playlist']);
  const [media, setMedia] = useState<Station | undefined>();
  const [scheduleVersion, setScheduleVersion] = useState<'v1'|'v2'| undefined>();

  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;

  useEffect(() => {
    if (listToDisplay && data && (data?.station || data?.playlist)) {
      const m = listToDisplay.find(m => m.id === (data?.station || data?.playlist));
      if (m) {
        setMedia(m as Station);
        setScheduleVersion('v2');
      }
    } else {
      setScheduleVersion('v1');
    }
  }, [listToDisplay, data]);

  useEffect(() => {
    if (data) {
      const {
        timeSlot: {
          start,
          end
        },
        bpmRange,
        genres,
        targetGroup: {
          gender,
          ageRange: {
            start: ageStart,
            end: ageEnd
          } = {
            start: 0,
            end: 50
          },
          styles
        }
      } = data;
      setVerySmall(differenceInSeconds(transformHourStringtoDate(end.hour), transformHourStringtoDate(start.hour)) <= 4800);
      setSmall(differenceInSeconds(transformHourStringtoDate(end.hour), transformHourStringtoDate(start.hour)) < 6000);

      const labelMood = getMoodFromRange(bpmRange as number[]);

      const indexMood = listMoods.findIndex(m => m.label === labelMood[0]);
      if (indexMood >= 0) {
        setImg(listMoods[indexMood].img);
      }

      setTitle(`${currentIdToLabel[labelMood[0]!]} (${genres.map(g => currentIdToLabel[g])})`);
      setSubTitle(`${currentIdToLabel[gender]}, ${ageStart} - ${ageEnd}, ${styles.reduce((acc, s, i) => {
        acc = `${acc} ${currentIdToLabel[s].toString()} ${i < styles.length -1 ? "+" : ""}`;
        return acc;
      }, "")}`);
    }
  }, [data, currentIdToLabel]);

  const {
    timeSlot: { start, end },
  } = data;

  const params = useMemo(
    () =>
      new URLSearchParams({
        id: data.id,
        originalDay: data.originalDay,
        day: data.timeSlot.start.day,
        startDate: data.timeSlot.start.hour,
        endDate: data.timeSlot.end.hour,
        repeat: data.repeat,
        days: JSON.stringify(data.days),
        mood: JSON.stringify(getMoodFromRange(data.bpmRange as number[])),
        genre: JSON.stringify(data.genres),
        target: JSON.stringify(data.targetGroup),
        startLimit,
        endLimit,
        idMedia: data?.station || data?.playlist || undefined,
        idCalendar
      } as Record<string, string>),
    [data, startLimit, endLimit, idCalendar]
  );

  const top = useMemo(() => getTopItemSchedule(start.hour), [start.hour]);
  const height = useMemo(
    () => getHeightItemSchedule(start.hour, end.hour),
    [start.hour, end.hour]
  );

  return (
    <Link to={`item?${params.toString()}`}>
      <Container
        top={top}
        height={height}
        left={leftPos}
        width={widthColumn}
      >
        <Bloc colorBG={bgColor[media?.type || 'station']}>
          {!scheduleVersion && (
            <TailSpin stroke={grey} height="2em" />
          )}
          {scheduleVersion === 'v2' && (
            <Column>
              {media && (
                <MediaCardCalendar
                  type={media?.type || 'station'}
                  media={media}
                  isSmall={isSmall}
                  isVerySmall={isVerySmall}
                />
              )}
            </Column>
          )}
          {scheduleVersion === 'v1' && (
            <Column>
              {img && !isSmall && <Icon src={img} alt={title} />}
              {title && subTitle && (
                <>
                  <Text size='xsmall'>{title}</Text>
                  <Text size="xsmall" color={grey} >{subTitle}</Text>
                </>
              )}
            </Column>
          )}
        </Bloc>
      </Container>
    </Link>
  );
}

const Container = styled.div<{
  top: number;
  height: number;
  left: number;
  width: number;
}>`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  width: ${({ width }) => `${width - 5}px`};
  height: ${({ height }) => `${height}px`};
  margin-left: 2.5px;
  padding: 5px;
  z-index: 10;
`;

const Bloc = styled.div<{colorBG: string;}>`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: ${({ colorBG }) => colorBG};
  padding: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Icon = styled.img`
  width: ${widthTrancheScheduleTablet - 30}px;
  height: ${widthTrancheScheduleTablet - 30}px;
  margin-bottom: 10px;
`;

