import {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import {PlayCircleFilled, CheckOutlined} from '@ant-design/icons';

import { veryVeryLightGrey, grey, redLightBG, purple } from '../../config/colors';
import { usePlayer } from '../../store';
import { TypeMedia, MoodLabel } from '../../services/SoundSuitServiceTypes';
import { listGenres, idToLabel, IdToLabel} from '../../config/hardData';
import { getMoodFromRange, capitalizeFirstLetter } from '../../utils/functions';
import CalendarImg from '../../assets/images/calendarImg.jpg';
// import components
import Text from '../atoms/Text';
import MultipleImagesThumbnail from '../atoms/MultipleImagesThumbnail';
import Tag from '../atoms/Tag';

type Props = {
  type: TypeMedia;
  media: any;
  closeModal?: () => void;
  isSelected: boolean;
  useFor?: 'media' | 'calendar';
};

const MediaRow = (props: Props) => {

  async function handleSelection() {
    try {
      await setMediaToPlay({id: media.id, type});
      toggleRefreshTracks();
      closeModal()
    } catch (error) {
      console.log("Error choosing media: ", error);
      notification.open({
        message: '',
        description: t('Notifications.requestFailed'),
      });
    }
  }

  const {
    media,
    type,
    closeModal,
    isSelected,
    useFor
  } = props;

  const [ t ] = useTranslation();

  const labelsType = useRef({
    station: t('Settings.stationLabel'),
    playlist: t('Settings.playlistLabel'),
    schedule: t('Schedule.scheduleTitle')
  }).current;

  const setMediaToPlay = usePlayer.getState().effects.setMediaToPlay;
  const toggleRefreshTracks = usePlayer.getState().reducers.toggleRefreshTracks;
  const currentIdToLabel: IdToLabel = useRef(idToLabel()).current;

  const [images, setImages] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [tagsMood, setTagsMood] = useState<(MoodLabel | undefined)[]>([]);
  const [labelBpm, setLabelBpm] = useState<string>('');

  useEffect(() => {
    if (type === 'playlist') {

      const {
        metrics: {
          genres,
          moods
        }
      } = media;

      const tgs = (genres as string[]).reduce<string[]>((acc, g, i) => {
        if (i === 3) {
          acc.push('...');
        } else if (i > 3) {
          return acc;
        } else {
          acc.push(g);
        }
        return acc;
      }, []);
      setTags(tgs);

      const tgsMood = (moods as string[]).reduce<string[]>((acc, m, i) => {
        if (i === 3) {
          acc.push('...');
        } else if (i > 3) {
          return acc;
        } else {
          acc.push(m);
        }
        return acc;
      }, []);

      setTagsMood(tgsMood as MoodLabel[]);


    } else if (media && Array.isArray(media.genres)) { 
      const tgs = (media.genres as string[]).reduce<string[]>((acc, g, i) => {
        if (i === 3) {
          acc.push('...');
        } else if (i > 3) {
          return acc;
        } else {
          acc.push(g);
        }
        return acc;
      }, []);
      setTags(tgs);
    }
    if (media && media.bpmRange) {
      const moods = getMoodFromRange(media.bpmRange);
      setLabelBpm(`${media.bpmRange[0]}-${media.bpmRange[1]} bpm`);
      const tgsMood = (moods as string[]).reduce<string[]>((acc, m, i) => {
        if (i === 3) {
          acc.push('...');
        } else if (i > 3) {
          return acc;
        } else {
          acc.push(m);
        }
        return acc;
      }, []);

      setTagsMood(tgsMood as MoodLabel[]);
    }
  }, [media]);

  useEffect(() => {

    switch (type) {
      case 'station':
        if (media && Array.isArray(media.genres)) {
          const imgs = media.genres.map((genre: string) => listGenres.find(lg => lg.label === genre)?.img); 
          setImages(imgs);
        }
        break;
      case 'schedule':
        setImages([CalendarImg]);
        break;
      case 'playlist':
        if (media && media?.coverImage) {
          const uri = /\.(png|jpg|jpeg)$/.test(media.coverImage) ? media.coverImage : 'https://assets.soundsuit.fm/playlist-600.png';
          setImages([uri]);
        }
        break;
      default:
        break;
    }
  }, [media]);

  return (
    <Container>
      <WrapperImage>
        {images.length > 0 && <MultipleImagesThumbnail imgs={images}  />}
      </WrapperImage>
      <Column>
        {useFor !== 'calendar' ? (
          isSelected ? (
            <WrapperEqualizer>
              <Equalizer
                src={require('../../assets/icons/equalizer_white.gif')}
              />
            </WrapperEqualizer>
          ):(
            <WrapperButton onClick={handleSelection}>
            <PlayCircleFilled style={{ fontSize: "32px" }} />
            </WrapperButton>
          )
        ):(
          isSelected && (
            <WrapperCheck>
              <CheckOutlined style={{ color: purple, fontSize: 24 }} />
            </WrapperCheck>
          )
        )}
        <WrapperTitle>
          <Text bold>{media.name || ''}</Text>
        </WrapperTitle>
        <WrapperDescription>
          {type === 'station' && (
            <Text
              color={purple}
              size="small"
            >
              {`${capitalizeFirstLetter(labelsType[media.type])} • ${labelBpm}`}
            </Text>
          )}
          {type === 'playlist' && (
            <Text
              color={purple}
              size="small"
            >
              {`${capitalizeFirstLetter(labelsType[media.type])} • ${media.tracks.length} ${t('menu.labelSongs')}`}
            </Text>
          )}
        </WrapperDescription>
        {['station', 'playlist'].includes(type) && (
          <>
            <WrapperTags>
              {tagsMood.map((g: string, i: number) => {

                if (g === '...') {
                  return (
                    <Tag
                      key={`${i}-${g}`}
                      type="mood"
                      size="small"
                    >
                      {g}
                    </Tag>
                  );
                }

                return (
                  <Tag
                    key={`${i}-${g}-mood`}
                    type="mood"
                    size="small"
                  >
                      {currentIdToLabel[g]}
                  </Tag>
                );
              })}
            </WrapperTags>
            <WrapperTags>
              {tags.map((g: string, i: number) => {

                if (g === '...') {
                  return (
                    <Tag
                      key={`${i}-${g}`}
                      type="genre"
                      size="small"
                    >
                      {g}
                    </Tag>
                  );
                }

                return (
                  <Tag
                    key={`${i}-${g}-mood`}
                    type="genre"
                    size="small"
                  >
                      {currentIdToLabel[g]}
                  </Tag>
                );
              })}
            </WrapperTags>
          </>
        )}
      </Column>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 116px;
  background-color: ${veryVeryLightGrey};
  border-radius: 16px;
  margin-bottom: 10px;
  padding: 10px;
  z-index: 0;
`;

const WrapperButton = styled.button`
  position: absolute;
  right: 10px;
  top: 0px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  z-index: 50;
  cursor: pointer;
`;
const Column = styled.div`
  position: relative;
  flex: 2;
  height: 100%;
  padding-left: 10px;
`;
const WrapperDescription = styled.div`
  width: 100%;
  margin-bottom: 0px;
`;
const WrapperImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  margin-bottom: 0px;
`;
const WrapperTags = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-top: auto;
  margin-bottom: 4px;
`;
const WrapperEqualizer = styled.div`
  position: absolute;
  right: 10px;
  top: 0px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${redLightBG};
  z-index: 50;
`;
const Equalizer = styled.img`
  width: 20px;
  height: 20px;
`;
const WrapperCheck = styled.div`
  position: absolute;
  right: 5px;
  top: 0px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
`;

export default MediaRow;