import React from 'react';
import styled from 'styled-components';
import {
  Routes,
  Route,
} from "react-router-dom";

import { veryVeryLightGrey } from '../../config/colors';
// import components
import PlaylistOptions from './PlaylistOptions';

interface Props {};

const PlaylistScreen: React.FC<Props> = props => {

  return (
    <Container>
      <Routes>
        <Route index element={<PlaylistOptions />} />
      </Routes>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${veryVeryLightGrey};
`;

export default PlaylistScreen;