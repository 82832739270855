import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { MediaToPlay} from "../../services/SoundSuitServiceTypes";
import { getStationById, getCalendar, getPlaylist } from '../../services/SoundSuitService';
// import components
import Text from "../atoms/Text";

type Props = {
  media: MediaToPlay
};

const SelectedMediaLabel = (props: Props) => {

  const {
    media: {
      id,
      type
    }
  } = props;


  const { data: dataStations } = useQuery(['station', id], () => {
    if (type === 'station' && id) {
      return getStationById(id)
    }
  });
  const { data: dataCalendar } = useQuery(['calendar', id], () => {
    if (type === 'schedule' && id) {
      return getCalendar(id)
    }
  });
  const { data: dataPlaylist } = useQuery(['playlist', id], () => {
    if (type === 'playlist' && id) {
      return getPlaylist(id)
    }
  });
  const [modeDescription, setModeDescription] = useState<string>('');

  useEffect(() => {
    if (type === 'schedule') {
      if (dataCalendar && Object.keys(dataCalendar).length > 0) {
        setModeDescription(dataCalendar.name);
      }
    } else if (type === 'station') {
      if (dataStations && Object.keys(dataStations).length > 0) {
        setModeDescription(dataStations.name);
      }
    } else if (type === 'playlist') {
      if (dataPlaylist && Object.keys(dataPlaylist).length > 0) {
        setModeDescription(dataPlaylist.name);
      }
    }
  }, [type, dataStations, dataCalendar, dataPlaylist]);

  return (
    <ButtonRow>
      <Text size="xsmall">
        {modeDescription}
      </Text>
    </ButtonRow>
  );
}

const ButtonRow = styled.div`

`;

export default SelectedMediaLabel;