import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Sound, {
  Volume
} from 'react-hifi';
import { useQuery, useQueryClient } from 'react-query';

import { usePlayer } from '../../store';
import { white } from '../../config/colors'; 
import {
  CurrentTrackData,
  Track
} from '../../services/SoundSuitServiceTypes';
import { fetchListOfTracks, loadUserProfile, setTrackPlayingForZone, updateIndexPlaylist } from '../../services/SoundSuitService';
import { getNowWithFormat, convertSongsToTracks } from '../../utils/functions';
// import components
import ControlsPlayer from '../molecules/ControlsPlayer';

interface Props {};

const PlayerBar: React.FC<Props> = props => {

  async function fetchTracksFromServer(currentIdsQueue: string[], toggleReloadQueue: boolean = false) {
    try {
      const now: string = getNowWithFormat();
      const songsToPlayNext = await queryClient.fetchQuery('track', () => fetchListOfTracks({
          count: 10,
          reject: currentIdsQueue,
          mood: 0,
          now,
          playWithSchedule,
          timezone,
          playNormalized: true,
          zone: zoneSelected
      }));
      if (!songsToPlayNext || songsToPlayNext.length <= 3) {
        setCriteriaError(true);
      } else {
        const tracksToPlayNext = convertSongsToTracks(songsToPlayNext);
        let newPlaylist = tracksToPlayNext;
        if(playlist.length > 0 && currentPosition >= 6) {
          newPlaylist = [
            ...playlist,
            ...tracksToPlayNext
          ];
          newPlaylist.splice(0, currentPosition - 3);
        }
        let realPosition = -1;
        if (typeof playlist[currentPosition] !== 'undefined') {
          const currentT = playlist[currentPosition];
          realPosition = newPlaylist.findIndex(t => t._id === currentT._id);
        }
        if (realPosition > -1){
          setPositionTrack(realPosition);
        }
        setPlaylist(newPlaylist);
        setCriteriaError(false);
        if (toggleReloadQueue) {
          toggleReloadQueueMeta();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function togglePlay(): void {
    if (status === Sound.status.PAUSED || status === Sound.status.STOPPED) {
      if(playlist.length > 0) {
        setStatus(Sound.status.PLAYING);
      }
    } else if (status === Sound.status.PLAYING) {
      setStatus(Sound.status.PAUSED);
    }
  }
  function handleCurrentData(data: CurrentTrackData):void {
    setCurrentTrackData(data);
  }
  function handlePlayerError(error) {
    console.log("Sound Error: ", error);
  }
  function handleNextSong() {
    if (currentPosition < playlist.length -1) {
      setPositionTrack(p => p + 1);
    }
  }
  function handlePrevSong() {
    if (typeof playlist[currentPosition - 1] !== 'undefined') {
      setPositionTrack(p => p - 1);
    }
  }

  const queryClient = useQueryClient();
  const { data } = useQuery('/userinfo', loadUserProfile);

  const timezone = useRef(Intl.DateTimeFormat().resolvedOptions().timeZone).current;
  const firstLoaded: React.MutableRefObject<0 | 1 | 2> = useRef(0);

  const playlist = usePlayer(playerState => playerState.state.playlist);
  const currentPosition = usePlayer(playerState => playerState.state.positionTrack);
  const status = usePlayer(playerState => playerState.state.status);
  const currentTrackData = usePlayer(playerState => playerState.state.currentTrackData);
  const volume = usePlayer(playerState => playerState.state.volume);
  const playWithSchedule = usePlayer(playerState => playerState.state.playWithSchedule);
  const refreshTracks = usePlayer(playerState => playerState.state.refreshTracks);
  const zoneSelected = usePlayer(playerState => playerState.state.zoneSelected);
  const mediaToPlay = usePlayer(playerState => playerState.state.mediaToPlay);
  const playlistTracks = usePlayer(playerState => playerState.state.playlistTracks);
  const isPlaylistShuffle = usePlayer(playerState => playerState.state.isPlaylistShuffle);

  const setPlaylist = usePlayer.getState().reducers.setPlaylist;
  const setStatus = usePlayer.getState().reducers.setStatus;
  const setCurrentTrackData = usePlayer.getState().reducers.setCurrentTrackData;
  const setPositionTrack = usePlayer.getState().reducers.setPositionTrack;
  const toggleReloadQueueMeta = usePlayer.getState().reducers.toggleReloadQueueMeta;
  const toggleRefreshTracks = usePlayer.getState().reducers.toggleRefreshTracks;
  const setCriteriaError = usePlayer.getState().reducers.setCriteriaError;

  const [ currentStream, setCurrentStream ] = useState<string>('');

  // Listen to the state of the queue to fetch new songs
  useEffect(() => {
    if ( (playlist.length - 1 - currentPosition) <= 3 ) {
      fetchTracksFromServer(playlist.map(t => t._id));
    }
  }, [currentPosition, playlist.length]);


  // load the playlist at mount 
  useEffect(() => {
    fetchTracksFromServer([]);
  }, []);

  // generate the url array for player
  useEffect(() => {
    async function sendTrackToZone(track: Track) {
      try {
        await setTrackPlayingForZone(zoneSelected, {
          trackId: track.id,
          source: process.env.REACT_APP_PLATFORM === 'electron' ? 'electron': 'web',
          metadata: track,
          started: new Date().toISOString()
        }); 
      } catch (error) {
        console.log("FAILED LOG TRACK PLAYED: ", error);
      }
    }

    async function setIndexForPlaylist(track: Track) {
      // If media is playlist, we update the index
      if (mediaToPlay && mediaToPlay.type === "playlist" && playlistTracks && playlistTracks.length > 0 && !isPlaylistShuffle) {
        if (track) {
          const index = playlistTracks.findIndex(id => id === track.id);
          if (index !== -1) {
            if (index === playlistTracks.length - 1) {
              await updateIndexPlaylist(mediaToPlay.id, 0);
            } else {
              await updateIndexPlaylist(mediaToPlay.id, index);
            }
          }
        }
      }
    }

    if(playlist.length > 0) {
      setCurrentStream(playlist[currentPosition].url);
      sendTrackToZone(playlist[currentPosition]);
      setIndexForPlaylist(playlist[currentPosition]);
    }
  }, [playlist, currentPosition, zoneSelected, mediaToPlay, playlistTracks, isPlaylistShuffle]);

  // update the playlist when change of settings
  useEffect(() => {

    async function fetchNewTracks() {
      fetchTracksFromServer([], true);
    }

    // we pass by the 2 firsts call that are the mount and the loading of the object data
    if(firstLoaded.current === 0) {
      firstLoaded.current = 1;
    } else if (firstLoaded.current === 1) {
      firstLoaded.current = 2;
    } else if (firstLoaded.current === 2) {
      fetchNewTracks();
    }
  }, [data?.bpmRange, data?.business_type, data?.genres, data?.styles, data?.customer_age]);

  // refresh the tracks list if asked
  useEffect(() => {
    async function fetchNewTracks() {
      fetchTracksFromServer([], true);
    }

    if(refreshTracks) {
      fetchNewTracks();
      toggleRefreshTracks();
    }
  }, [refreshTracks]);


  return (
    <Container>
      {currentStream.length > 0 && (
        <Sound
          url={currentStream}
          playStatus={status}
          onError={handlePlayerError}
          onPlaying={handleCurrentData}
          onFinishedPlaying={handleNextSong}
          position={currentTrackData.position}
        >
          <Volume value={volume} />
        </Sound>
      )}
      <ControlsPlayer
        onNext={handleNextSong}
        onPrevious={handlePrevSong}
        onTogglePlayback={togglePlay}
      />
    </Container>
  );
}

const Container = styled.div`
  grid-row: 2;
  grid-column: 2;
  padding-left: 50px;
  box-shadow: 0px -2px 3px rgba(50, 50, 50, 0.1);
  background-color: ${white};
`;

export default PlayerBar;